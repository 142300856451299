import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Image } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  backgroundImage?: ImageProps
  created?: string
  languageCode: string
  title?: string
  URL: string
}

export const Post = memo(function Post({
  backgroundImage,
  created,
  languageCode,
  title,
  URL,
}: Props) {
  if (!URL) {
    return null
  }

  return (
    <Container to={URL}>
      {backgroundImage ? <Image {...backgroundImage} /> : null}
      <Wrapper>
        <PostContent className="post-content">
          <PostWrap className="post-wrap">
            {created ? (
              <Created>
                {new Date(created).toLocaleDateString(languageCode, {
                  year: 'numeric',
                  month: 'long',
                })}
              </Created>
            ) : null}
            {title ? <Title>{title}</Title> : null}
          </PostWrap>
          <CTA
            className="post-cta"
            label={useVocabularyData('read', languageCode)}
            noActions
          />
        </PostContent>
      </Wrapper>
    </Container>
  )
})

const Container = styled(Link)`
  width: calc(33.333% - 0.9375rem);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  margin-top: 1.5rem;
  padding-bottom: calc(33.333% - 0.9375rem);
  position: relative;
  &:first-of-type {
    width: 100%;
    padding-bottom: 35%;
    &:hover {
      .post-cta {
        &:after {
          top: 0;
        }
      }
    }
    .post-content,
    .post-wrap {
      transform: translateY(0);
    }
    .post-cta {
      background: ${({ theme }) => theme.colors.variants.neutralDark1};
    }
  }
  &:hover {
    &:before {
      opacity: 0.7;
    }
    img {
      transform: scale(1.1);
    }
    .post-content,
    .post-wrap {
      transform: translateY(0);
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    backface-visibility: hidden;
    transition: 0.3s ease-in-out;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out;
  }

  @media (max-width: 1023px) {
    width: calc(50% - 0.5625rem);
    margin-top: 1.125rem;
    padding-bottom: calc(50% - 0.5625rem);
    .post-content,
    .post-wrap {
      transform: translateY(0);
    }
    &:first-of-type {
      width: calc(50% - 0.5625rem);
      padding-bottom: calc(50% - 0.5625rem);
      .post-cta {
        background: ${({ theme }) => theme.colors.variants.primaryLight1};
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-bottom: 100%;
    &:first-of-type {
      width: 100%;
      padding-bottom: 100%;
    }
  }
`

const Wrapper = styled.div`
  overflow: hidden;
  position: absolute;
  bottom: 1.875rem;
  right: 3.75rem;
  left: 1.875rem;
  z-index: 2;

  @media (max-width: 1023px) {
    right: 1.875rem;
  }
`

const PostContent = styled.div`
  transition: 0.3s ease-in-out;
  transform: translateY(100%);
`

const PostWrap = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  transition: 0.3s ease-in-out;
  transform: translateY(-100%);
`

const Created = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.1125rem;
  line-height: 0.9375rem;
  margin-bottom: 0.4375rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  max-width: 270px;
  font-size: 1.375rem;
  line-height: 1.875rem;
`

const CTA = styled(Button)`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  margin-top: 1.25rem;

  @media (max-width: 1023px) {
    margin-top: 1rem;
  }
`
