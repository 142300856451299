import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  alt?: string
  className?: string
  decoding?: 'auto' | 'async'
  description?: string
  height?: number
  media?: string
  sources?: string
  src?: string
  srcSet?: string
  width?: number
}

export const Image = memo(function Image({
  alt,
  className,
  decoding,
  height,
  media,
  sources,
  src,
  width,
}: Props) {
  return (
    <Picture className={className}>
      <Source srcSet={sources} media={media || undefined} type="image/webp" />
      <Img
        alt={alt}
        src={src}
        decoding={decoding}
        loading="lazy"
        height={height}
        width={width}
      />
    </Picture>
  )
})

const Picture = styled.picture``

const Source = styled.source``

const Img = styled.img``
